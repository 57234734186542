import React, { createContext, useState, useEffect } from "react";

const AuthContext = createContext();
const DOMAIN = "https://pyaar.ai";
// const DOMAIN = "http://localhost:8008";

// Utility function to get a cookie by name
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let cookie of cookies) {
      cookie = cookie.trim();
      // Check if this cookie string begins with the name we want
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() => {
    try {
      const tokens = localStorage.getItem("authTokens");
      return tokens ? JSON.parse(tokens) : null;
    } catch (error) {
      // If there's any error parsing the tokens, remove the invalid data
      localStorage.removeItem("authTokens");
      return null;
    }
  });
  const [user, setUser] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // On component mount, read the CSRF token from cookies
  useEffect(() => {
    const token = getCookie("csrftoken");
    setCsrfToken(token);
  }, []);

  useEffect(() => {
    if (authTokens) {
      fetchUserData();
    }
  }, [authTokens]);

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${DOMAIN}/api/auth/user/`, {
        method: "GET",
        headers: {
          "Authorization": `Token ${authTokens}`,
        },
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setUser(data);
        setSuccess(true);
      } else {
        setError("Failed to fetch user data.");
        logout();
      }
    } catch (error) {
      setError("An error occurred while fetching user data.");
      logout();
    } finally {
      setLoading(false);
    }
  };

  const login = async (username, password) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch(`${DOMAIN}/api/auth/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
          "Referer": DOMAIN,
        },
        credentials: "include", // ensures cookies (and thus the session) are included
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        setAuthTokens(data.key);
        localStorage.setItem("authTokens", JSON.stringify(data.key));
        setSuccess(true);
        // fetchUserData() removed - useEffect will handle this
      } else {
        const errData = await response.json();
        setError(errData.detail || "Login failed.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const signup = async (username, email, password1, password2) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch(`${DOMAIN}/api/auth/registration/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
          "Referer": DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({ username, email, password1, password2 }),
      });

      if (response.ok) {
        const data = await response.json();
        setAuthTokens(data.key);
        localStorage.setItem("authTokens", JSON.stringify(data.key));
        setSuccess(true);
        // fetchUserData() removed - useEffect will handle this
      } else {
        const errData = await response.json();
        setError(errData.username?.[0] || "Signup failed.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      await fetch(`${DOMAIN}/api/auth/logout/`, {
        method: "POST",
        headers: {
          "X-CSRFToken": csrfToken,
          "Referer": DOMAIN,
        },
        credentials: "include",
      });
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("authTokens");
      setSuccess(false);
      setLoading(false);
    }
  };

  const requestPasswordReset = async (email) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch(`${DOMAIN}/api/auth/password/reset/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
          "Referer": DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSuccess(true);
      } else {
        const errData = await response.json();
        setError(errData.email?.[0] || "Password reset request failed.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authTokens,
        user,
        login,
        signup,
        logout,
        requestPasswordReset,
        loading,
        error,
        success,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
